import { usePath } from 'raviger'
import { FunctionComponent, useCallback } from 'react'
import { RouteItem, useRouteHelper } from 'shared/hooks/useRouteHelper'
import { NavigationItem } from './NavigationItem'

interface SidebarNavigationProps {
	className?: string
}

const SidebarNavigationTop: FunctionComponent<SidebarNavigationProps> = (props) => {
	const { visibleNavigationRoutes } = useRouteHelper()

	const path = usePath()
	const getClassNames = useCallback((): string => {
		const classes: string[] = ['sidebar-navigation', 'sidebar-navigation--top']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}, [props.className])

	return (
		<nav className={getClassNames()}>
			{visibleNavigationRoutes
				.filter((item: RouteItem) => {
					/**
					 * - show only the items where showInNavigation is set to true
					 * - check if item should not be hidden if logged in
					 */
					return item.showInTopNavigation
				})
				.map((item, index) => (
					<NavigationItem route={item} path={path} key={'sidebar-navigation-top-' + index}></NavigationItem>
				))}
		</nav>
	)
}

export default SidebarNavigationTop
