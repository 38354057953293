import { IconType } from 'components/Icons'
import { useLocationChange } from 'raviger'

import { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Icon, { IconSize } from 'shared/components/Icon'
import { useBreakpointClasses } from 'shared/hooks/useBreakpointClasses'
import useNavigationState from '../state/useNavigationState'

const breakpoints: { [key: string]: number } = {
	small: 0,
	smallMedium: 360,
	medium: 1024,
	large: 1400,
}
const NavigationToggle: FunctionComponent<{ className?: string }> = (props) => {
	const activeBreakpoints = useBreakpointClasses(breakpoints)
	const [navigationState, navigationActions] = useNavigationState()

	useLocationChange(
		() => {
			if (!activeBreakpoints?.medium && navigationState.open) {
				navigationActions.setOpen(false)
			}
		},
		{ onInitial: false }
	)

	useEffect(() => {
		if (activeBreakpoints === undefined) {
			return
		}

		if (activeBreakpoints?.medium) {
			navigationActions.setOpen(true)
		} else {
			navigationActions.setOpen(false)
		}
	}, [activeBreakpoints, navigationActions])

	useEffect(() => {
		document.documentElement.classList.toggle('sidebar-closed', !navigationState.open)
		document.documentElement.classList.toggle('sidebar-open', navigationState.open)
	}, [navigationState])

	const { t } = useTranslation()

	return (
		<div className={`navigation-toggle ${props.className}`}>
			<label className="navigation-toggle__label" htmlFor="navigation-toggle">
				<Icon
					className="navigation-toggle__icon"
					type={IconType.arrow}
					size={IconSize.small}
					rotate={navigationState.open ? 180 : 0}
				/>
				<span className="navigation-toggle__text bold-small-heading">
					{t(`component.sidebar.menu.${navigationState.open ? 'open' : 'closed'}`)}
				</span>
			</label>

			<input
				className="navigation-toggle__checkbox"
				type="checkbox"
				id="navigation-toggle"
				onChange={() => navigationActions.setOpen(!navigationState.open)}
				checked={navigationState.open}
			/>
		</div>
	)
}

export default NavigationToggle
