export const clearSessionStorage = () => {
	window.sessionStorage.clear()
}

export const getSessionStorage = (key: string, initialValue?: any) => {
	const item = window.sessionStorage.getItem(key)

	if (null === item && initialValue) {
		window.sessionStorage.setItem(key, JSON.stringify(initialValue))
	}

	return item ? JSON.parse(item) : initialValue
}

export const setSessionStorage = (key: string, value: any) => {
	window.sessionStorage.setItem(key, JSON.stringify(value))

	return value
}

export const removeSessionStorage = (key: string) => {
	window.sessionStorage.removeItem(key)
}
