import { IconType } from 'components/Icons'
import { useAuth } from 'hooks/useAuth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from 'shared/components/Icon'
import VersionNumber from '../shared/components/VersionNumber'
import Logo from './Logo'
import NavigationToggle from './NavigationToggle'
import SidebarNavigation from './SidebarNavigation'
import SidebarNavigationTop from './SidebarNavigationTop'
const Sidebar: React.FC = () => {
	const { t } = useTranslation()
	const { userMetaData } = useAuth()

	return (
		<aside className="sidebar">
			<div className="sidebar__outer-wrapper">
				<div className="sidebar__inner-wrapper">
					<Logo className="sidebar__logo" />

					<NavigationToggle className="sidebar__navigation-toggle" />
					<div className="sidebar__mobile-navigation-wrapper">
						<SidebarNavigationTop className="sidebar__secondary-navigation" />
						<SidebarNavigation className="sidebar__navigation" />
						<div className="sidebar__spacer">
							<hr className="hr  margin--horizontal no-margin--top no-margin--bottom" />
						</div>
						<VersionNumber />

						<div className="sidebar__user flex flex--align-items-center padding">
							<Icon
								className="margin--horizontal margin--small no-margin--left"
								color="var(--color-white)"
								type={IconType.profile}
							/>
							<div className="flex flex--direction-column">
								<span className="text-color-gold text-transform-uppercase font-size-s">
									{t('component.sidebar.loggedInAs')}
								</span>
								<span className="font-family-regular text-color-white margin--vertical margin--small no-margin--bottom">
									{userMetaData ? (
										<>
											{userMetaData.firstName}
											<br />
											{userMetaData.lastName}
										</>
									) : (
										t('component.sidebar.notLoggedIn')
									)}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</aside>
	)
}

export default Sidebar
