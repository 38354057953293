// Thanks to https://usehooks.com/useAuth/

import { Auth } from 'aws-amplify'
import { UserMetadataData } from 'graphql'
import i18next, { DefaultTFuncReturn } from 'i18next'
import Cookies from 'js-cookie'
import { usePath } from 'raviger'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import routesDictionary from 'routes'
import { UserGroup } from 'shared/enums'
import isDev from 'shared/helper/isDev'
import { clearLocalStorage } from 'shared/helper/localStorage'
import { clearSessionStorage } from 'shared/helper/sessionStorage'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import usePageVisibility from 'use-page-visibility'
import useAuthApi, { AuthQueryKey, UserRoles } from './useAuthApi'

let refreshTokenTimeout: NodeJS.Timeout
let autoLogoutTimeout: NodeJS.Timeout

// TODO: refactor AuthContext class with proper types
export class AuthContext {
	public data?: any
	public userData?: UserData
	public userMetaData?: UserMetadataData
	public profileData?: ProfileData
	public signin?: any
	public sendMfaCode?: any
	public signout?: any
	public userInitialized?: boolean
	public checkUserAuthentication?: any

	/**
	 * only needed to prevent linting errors. there is no method for that at the moment.
	 * this method use used in other projects, which share the same components
	 */
	public sendChallengeAnswer?: any

	constructor(
		userData: UserData,
		userMetaData: UserMetadataData,
		signin: any,
		sendMfaCode: any,
		signout: any,
		userInitialized: boolean,
		checkUserAuthentication: any
	) {
		this.userData = userData
		this.userMetaData = userMetaData
		this.signin = signin
		this.sendMfaCode = sendMfaCode
		this.signout = signout
		this.userInitialized = userInitialized
		this.checkUserAuthentication = checkUserAuthentication
	}
}

export interface UserData {
	email: string
	'cognito:username': string
	'cognito:groups': UserGroup[]
	groups: UserGroup[] | UserRoles[]
	roles: string
	given_name: string
	name: string
	sub: string
	iat: number
}

export interface UserAddress {
	type: string
	recipient: string
	careOf: String
	city: string
	countryCode: string
	houseNumber: string
	street: string
	validFrom: string
	validTo: string
	zip: string
}

export interface UserBankDetails {
	accountHolder: string
	isDefaultAccountHolder: boolean
	iban: string
	bic: string
	accountNumber: string
	bankNationalId: string
	countryCode: string
	bankName: string
	validFrom: string
}
export interface ProfileData {
	email: string
	phoneNr: string
	postalAddress: UserAddress
	livingAddress: UserAddress
	bankDetails: UserBankDetails
}

export interface AuthError {
	successful: boolean
	e?: string
	code?: string
	name?: string
	message?: string
	errorMessages?: (string | React.ReactElement | DefaultTFuncReturn)[]
}

export enum ErrorType {
	passwordResetRequired = 'PasswordResetRequiredException',
	newPasswordRequired = 'NewPasswordRequired',
	passwordMismatch = 'PasswordMismatch',
	userNotFound = 'UserNotFoundException',
	limitExceeded = 'LimitExceededException',
	notAString = 'SerializationException',
	codeMismatch = 'CodeMismatchException',
	enableSoftwareTokenMFAException = 'EnableSoftwareTokenMFAException',
	invalidParameter = 'InvalidParameterException',
	sessionInvalid = 'SessionInvalid',
	mfaSetup = 'MfaSetup',
	mfa = 'Mfa',
}

export interface UserCredentials {
	username: string
	password: string
}

const authContext = createContext<AuthContext>({})

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
	const auth: any = useProvideAuth()
	return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
	const authApi = useAuthApi()
	const path = usePath()
	const { getMainPath, navigateTo } = useRouteHelper()

	// const [user, setUser] = useState<UserData | undefined>()
	const userData = useRef<UserData | undefined>()
	const tokenIssuedAt = useRef<{ iat: number; diffToLocalTime: number }>()

	// just used to force a re-render
	const [userInitialized, setUserInitialized] = useState<boolean>(false)

	const { data } = useQuery(AuthQueryKey.userMetaData, authApi.getUserData, { enabled: userInitialized })

	useEffect(() => {
		const lang = data?.userMetaData?.settings?.languagePreferred
		if (lang && lang !== i18next.language) {
			i18next.changeLanguage(lang, () => {
				window.location.reload()
			})
		}
	}, [data?.userMetaData])

	const queryClient = useQueryClient()

	const updateTokenIssuedAt = (currentUserData: UserData) => {
		const { iat } = currentUserData
		const diffToLocalTime = iat * 1000 - new Date().getTime()

		tokenIssuedAt.current = {
			iat: iat * 1000,
			diffToLocalTime,
		}
	}

	const signin = async (
		credentials: UserCredentials,
		prefferedMfa?: 'sms' | 'email'
	): Promise<boolean | unknown | AuthError> => {
		try {
			const response = await Auth.signIn({
				...credentials,
				validationData: {
					mfaType: prefferedMfa,
				},
			})

			if (response.challengeName === 'CUSTOM_CHALLENGE') {
				return response
			}

			checkUserAuthentication(false)

			return true
		} catch (e: any) {
			const error = { e, successful: false } as AuthError

			return error
		}
	}

	const sendMfaCode = async (user: unknown, code: string): Promise<boolean | AuthError> => {
		try {
			const response = await Auth.sendCustomChallengeAnswer(user, code)

			if (response.challengeName === 'CUSTOM_CHALLENGE') {
				return {
					successful: false,
					name: 'INVALID',
				}
			}

			checkUserAuthentication(false)

			return true
		} catch (e: any) {
			return {
				successful: false,
				name: 'EXPIRED',
			} as AuthError
		}
	}

	const signout = async () => {
		try {
			await Auth.signOut({ global: true })
		} finally {
			/**
			 * Cookies that should not be deleted after logout
			 */
			const cookiesToKeep = ['i18next']

			Object.keys(Cookies.get()).forEach((cookieName) => {
				/**
				 * clear cookies from domain with an without leading dot,
				 * to make sure they are deleted in all browsers
				 */

				if (cookiesToKeep.includes(cookieName)) {
					return
				}

				const neededAttributes = {
					path: '/',
					domain: `.${process.env.REACT_APP_COOKIE_DOMAIN}`,
				}
				Cookies.remove(cookieName, neededAttributes)

				neededAttributes.domain = String(process.env.REACT_APP_COOKIE_DOMAIN)
				Cookies.remove(cookieName, neededAttributes)
			})

			if (isDev()) {
				clearLocalStorage()
			}

			clearSessionStorage()
			clearTimeout(autoLogoutTimeout)
			queryClient.resetQueries()
			userData.current = undefined
			tokenIssuedAt.current = undefined
			setUserInitialized(false)
		}
	}

	/**
	 * HINT:
	 * currently amplify does not return an error if the access token has
	 * been revoked, e.g. by logging out on another device
	 *
	 * as a workaround the currentUserInfo is checked.if it returns an
	 * empty object, the access token has been revoked
	 */
	const checkUserSession = () => {
		Auth.currentUserInfo().then((userObject) => {
			if (null !== userObject && Object.entries(userObject).length === 0 && userObject.constructor === Object) {
				navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
			}
		})
	}

	const checkUserAuthentication = async (
		bypassCache: boolean = false,
		callback?: (userData: UserData | undefined) => void
	) => {
		Auth.currentAuthenticatedUser({
			bypassCache, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
		})
			.then((currentUser) => {
				const userNotInitialized = undefined === userData.current

				const updatedUserData = currentUser.signInUserSession.idToken.payload

				updateTokenIssuedAt(updatedUserData)

				const userGroups: string[] = updatedUserData['cognito:groups'] || []
				const userRoles: string[] = updatedUserData.roles?.split(',') || []
				updatedUserData.groups = [...userGroups, ...userRoles]

				if (updatedUserData.groups.length === 0) {
					updatedUserData.groups = [UserGroup.None]
				}

				userData.current = updatedUserData

				/**
				 * start the autoLogoutTimeout after every tokenRefresh
				 */
				if (true === bypassCache) {
					clearTimeout(autoLogoutTimeout)
					const autoLogoutTime = Number(process.env.REACT_APP_AUTO_LOGOUT_IN_MINUTES) * 60 * 1000

					autoLogoutTimeout = setTimeout(
						() => navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true }),
						autoLogoutTime
					)
				}

				if (true === userNotInitialized) {
					setUserInitialized(true)
				}

				callback?.(updatedUserData)
			})
			.catch((err) => {
				if (undefined !== userData.current) {
					navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
				}
			})

		if (bypassCache === false) {
			checkUserSession()
		}
	}

	/**
	 * this effect only runs after changing the page
	 */
	useEffect(() => {
		if (undefined === tokenIssuedAt.current || undefined === userData.current) {
			return
		}

		clearTimeout(refreshTokenTimeout)

		const tokenAge = new Date().getTime() + tokenIssuedAt.current.diffToLocalTime - tokenIssuedAt.current.iat
		const maxTokenAge = Number(process.env.REACT_APP_AUTO_LOGOUT_IN_MINUTES) * 60 * 1000
		const remainingTokenValidity = maxTokenAge - tokenAge
		const autoRefreshTime = Number(process.env.REACT_APP_AUTO_TOKEN_REFRESH_IN_SECONDS) * 1000

		if (remainingTokenValidity <= 0) {
			navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
			return
		}

		/**
		 * if the current tokens' validity is less than the autoRefreshTimeout,
		 * the token has to be refreshed immediately, to prevent it from being invalidated
		 *
		 * otherwise start a timer to refresh the token automatically after
		 * n seconds (autoRefreshTimeout).
		 * this only happens if the user does not interact with the app for the time
		 * of the autoRefreshTimeout
		 *
		 * as this effect only runs after changing the page or making an api request,
		 * maximum valid user sessing is maxTokenAge + autoRefreshTimeout
		 */
		if (autoRefreshTime > remainingTokenValidity) {
			checkUserAuthentication(true)
		} else {
			refreshTokenTimeout = setTimeout(() => {
				checkUserAuthentication(true)
			}, autoRefreshTime)

			checkUserSession()
		}

		return () => clearTimeout(refreshTokenTimeout)

		// eslint-disable-next-line
	}, [path])

	/**
	 * 1. this effect makes sure that the current user is loaded again when reloading the page
	 * 2. it clears the autoLogoutTimeout when the view is destroyed
	 */
	useEffect(() => {
		checkUserAuthentication(true)

		return () => clearTimeout(autoLogoutTimeout)
		// eslint-disable-next-line
	}, [])

	/**
	 * run checkUserAuthentication with bypassCache set to false when page becomes
	 * visible again, to check if user was logged out
	 */
	usePageVisibility((visible: boolean) => {
		if (!visible) {
			return
		}

		if (undefined === tokenIssuedAt.current || undefined === userData.current) {
			return
		}

		checkUserAuthentication(false)
	})

	// Return the user object and auth methods
	return {
		data: data,
		userData: userData.current,
		userMetaData: data?.userMetaData,
		profileData: data?.profileData,
		signin,
		sendMfaCode,
		signout,
		userInitialized,
		checkUserAuthentication,
	}
}
