import { Amplify, Auth } from 'aws-amplify'
import { createRoot } from 'react-dom/client'
import './i18n'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import App from './views/App'

fetch(process.env.REACT_APP_CONFIG_FILE_PATH as string)
	.then((reponse: any) => reponse.json())
	.then((data) => {
		const config = data['move37-rentnerportal']
		Amplify.configure(config)

		Amplify.configure({
			API: {
				graphql_endpoint: config.graphqlEndpoint,
				graphql_headers: async () => {
					return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() }
				},
			},
			Auth: {
				authenticationFlowType: 'CUSTOM_AUTH',
			},
		})

		if ('local' !== process.env.REACT_APP_CUSTOM_NODE_ENV) {
			Amplify.configure({
				Auth: {
					cookieStorage: {
						domain: window.location.host,
						expires: '', // this sets the cookie to a session cookie
						secure: true,
					},
				},
			})
		}
	})
	.then(() => {
		const container = document.getElementById('root')
		if (!container) {
			return
		}

		const root = createRoot(container)
		root.render(<App />)
	})

serviceWorkerRegistration.register({
	onUpdate: async (registration) => {
		if (registration && registration.waiting) {
			await registration.unregister()
			window.postMessage({ appUpdateAvailable: true }, window.location.origin)
			registration.waiting.postMessage({ type: 'SKIP_WAITING' })
		}
	},
})
