import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useBreakpointClasses } from 'shared/hooks/useBreakpointClasses'
import { ProvideAuth } from '../hooks/useAuth'
import RouteWrapper from './RouteWrapper'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 10000,
		},
	},
})

const breakpoints: { [key: string]: number } = {
	small: 0,
	smallMedium: 360,
	medium: 1024,
	large: 1400,
}

const App: React.FC = () => {
	useBreakpointClasses(breakpoints)

	return (
		<QueryClientProvider client={queryClient}>
			<ProvideAuth>
				<RouteWrapper />
			</ProvideAuth>
		</QueryClientProvider>
	)
}

export default App
