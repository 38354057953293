import { ReactComponent as AnimatedCheckmarkIcon } from 'assets/animated-checkmark.svg'
import { ReactComponent as AnimatedRadiobuttonIcon } from 'assets/animated-radiobutton.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as IconDelegate } from 'assets/icons/delegate.svg'
import { ReactComponent as IconEyeCrossed } from 'assets/icons/eye-crossed.svg'
import { ReactComponent as IconEye } from 'assets/icons/eye.svg'

import { ReactComponent as IconDelete } from 'assets/icons/delete.svg'
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg'
import { ReactComponent as InvalidIcon } from 'assets/icons/invalid.svg'
import { ReactComponent as ListMarkerCheck } from 'assets/icons/list-marker-check.svg'
import { ReactComponent as ListMarkerCross } from 'assets/icons/list-marker-cross.svg'
import { ReactComponent as ListMarkerNeutral } from 'assets/icons/list-marker-neutral.svg'
import { ReactComponent as IconLockOpen } from 'assets/icons/lock-open.svg'
import { ReactComponent as IconLock } from 'assets/icons/lock.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/nanoicon-help.svg'
import { ReactComponent as IconLargeHelp } from 'assets/menuIcons/icon-help.svg'

import { ReactComponent as InfoIcon } from 'assets/icons/nanoicon-info.svg'
import { ReactComponent as NotSelectableIcon } from 'assets/icons/not-selectable.svg'
import { ReactComponent as IconOpen } from 'assets/icons/open.svg'
import { ReactComponent as IconPageUp } from 'assets/icons/page-up.svg'
import { ReactComponent as IconPhone } from 'assets/icons/phone.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import { ReactComponent as IconProfile } from 'assets/menuIcons/icon-adresse.svg'
import { ReactComponent as IconLogout } from 'assets/menuIcons/icon-ausloggen.svg'
import { ReactComponent as IconPayout } from 'assets/menuIcons/icon-auszahlung.svg'
import { ReactComponent as IconComplaints } from 'assets/menuIcons/icon-beschwerden.svg'
import { ReactComponent as IconLetter } from 'assets/menuIcons/icon-briefe.svg'
import { ReactComponent as IconCall } from 'assets/menuIcons/icon-call.svg'
import { ReactComponent as IconChart } from 'assets/menuIcons/icon-chart.svg'
import { ReactComponent as IconChat } from 'assets/menuIcons/icon-chat.svg'
import { ReactComponent as IconDocuments } from 'assets/menuIcons/icon-dokumente.svg'
import { ReactComponent as IconDeferredCompensation } from 'assets/menuIcons/icon-entgeltumwandlung.svg'
import { ReactComponent as IconFundComposition } from 'assets/menuIcons/icon-fondszusammensetzung.svg'
import { ReactComponent as IconHouse } from 'assets/menuIcons/icon-house.svg'
import { ReactComponent as IconInbox } from 'assets/menuIcons/icon-incomingletter.svg'
import { ReactComponent as IconInfo } from 'assets/menuIcons/icon-info.svg'
import { ReactComponent as IconEmail } from 'assets/menuIcons/icon-kommunikation.svg'
import { ReactComponent as IconCard } from 'assets/menuIcons/icon-konto.svg'
import { ReactComponent as IconPending } from 'assets/menuIcons/icon-pending.svg'
import { ReactComponent as IconPensionerDashboard } from 'assets/menuIcons/icon-pensioner-dashboard.svg'
import { ReactComponent as IconRegister } from 'assets/menuIcons/icon-register.svg'
import { ReactComponent as IconDashboard } from 'assets/menuIcons/icon-sachbearbeiter-dashboard.svg'
import { ReactComponent as IconBaseData } from 'assets/menuIcons/icon-stammdaten.svg'
import { ReactComponent as IconSearch } from 'assets/menuIcons/icon-suche.svg'
import { ReactComponent as IconTicketApprove } from 'assets/menuIcons/icon-ticket-approve.svg'
import { ReactComponent as IconUploads } from 'assets/menuIcons/icon-useruploads.svg'
import { ReactComponent as IconPension } from 'assets/menuIcons/icon-versorgungsguthaben.svg'
import { ReactComponent as IconContract } from 'assets/menuIcons/icon-vertrag.svg'
import { ReactComponent as IconConversions } from 'assets/menuIcons/icon-wandlungen.svg'

export enum IconType {
	animatedCheckmark,
	animatedRadiobutton,
	checkmark,
	notSelectable,
	invalid,
	arrow,
	close,
	help,
	largeHelp,
	info,
	profile,
	logout,
	payout,
	complaints,
	letter,
	call,
	clock,
	chart,
	chat,
	dashboard,
	documents,
	deferredCompensation,
	fundComposition,
	inbox,
	email,
	card,
	pending,
	baseData,
	uploads,
	pension,
	contract,
	conversions,
	delegate,
	delete,
	open,
	pageUp,
	calendar,
	search,
	pensionerDashboard,
	settings,
	ticketApprove,
	house,
	listMarkerNeutral,
	listMarkerCheck,
	listMarkerCross,
	lockOpen,
	edit,
	phone,
	lock,
	register,
	eye,
	eyeCrossed,
}

export const IconAssignment = {
	[IconType.edit]: IconEdit,
	[IconType.phone]: IconPhone,
	[IconType.lock]: IconLock,
	[IconType.animatedCheckmark]: AnimatedCheckmarkIcon,
	[IconType.checkmark]: CheckmarkIcon,
	[IconType.animatedRadiobutton]: AnimatedRadiobuttonIcon,
	[IconType.arrow]: ArrowIcon,
	[IconType.close]: CloseIcon,
	[IconType.delegate]: IconDelegate,
	[IconType.delete]: IconDelete,
	[IconType.largeHelp]: IconLargeHelp,
	[IconType.help]: HelpIcon,
	[IconType.info]: InfoIcon,
	[IconType.clock]: IconPending,
	[IconType.profile]: IconProfile,
	[IconType.logout]: IconLogout,
	[IconType.payout]: IconPayout,
	[IconType.complaints]: IconComplaints,
	[IconType.letter]: IconLetter,
	[IconType.call]: IconCall,
	[IconType.chart]: IconChart,
	[IconType.chat]: IconChat,
	[IconType.dashboard]: IconDashboard,
	[IconType.documents]: IconDocuments,
	[IconType.deferredCompensation]: IconDeferredCompensation,
	[IconType.fundComposition]: IconFundComposition,
	[IconType.inbox]: IconInbox,
	[IconType.info]: IconInfo,
	[IconType.email]: IconEmail,
	[IconType.card]: IconCard,
	[IconType.pending]: IconPending,
	[IconType.baseData]: IconBaseData,
	[IconType.uploads]: IconUploads,
	[IconType.pension]: IconPension,
	[IconType.contract]: IconContract,
	[IconType.conversions]: IconConversions,
	[IconType.open]: IconOpen,
	[IconType.pageUp]: IconPageUp,
	[IconType.calendar]: IconCalendar,
	[IconType.search]: IconSearch,
	[IconType.pensionerDashboard]: IconPensionerDashboard,
	[IconType.settings]: SettingsIcon,
	[IconType.ticketApprove]: IconTicketApprove,
	[IconType.notSelectable]: NotSelectableIcon,
	[IconType.invalid]: InvalidIcon,
	[IconType.house]: IconHouse,
	[IconType.listMarkerCheck]: ListMarkerCheck,
	[IconType.listMarkerNeutral]: ListMarkerNeutral,
	[IconType.listMarkerCross]: ListMarkerCross,
	[IconType.lockOpen]: IconLockOpen,
	[IconType.register]: IconRegister,
	[IconType.eye]: IconEye,
	[IconType.eyeCrossed]: IconEyeCrossed,
}
