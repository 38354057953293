import { availableLanguages, sourceLanguage } from 'i18n-languages'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n.use(backend)
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		// lng: 'de',
		fallbackLng: sourceLanguage,
		supportedLngs: availableLanguages,
		backend: {
			loadPath: `/locales/{{lng}}.json`,
		},
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		detection: {
			order: ['cookie', 'htmlTag'],
			caches: ['cookie'],
		},
		react: {
			transEmptyNodeValue: '️️⚠️ MISSING TRANSLATION ⚠️', // what to return for empty Trans
			transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
			transKeepBasicHtmlNodesFor: ['h1', 'h2', 'h3', 'h4', 'p', 'br', 'strong', 'i', 'b', 'ul', 'li', 'hr'], // don't convert to <1></1> if simple react elements
		},
	})

export default i18n
