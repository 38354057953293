import logo from 'assets/decadia-logo-white@2x.png'
import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

const Logo: FunctionComponent<{ className?: string; onlyStripes?: boolean }> = (props) => {
	const { t } = useTranslation()

	return (
		<div className={classNames('logo', props.className, { 'logo--only-stripes': props.onlyStripes })}>
			{props.onlyStripes !== true && (
				<img src={logo} width="123" height="87" alt={t<string>('partial.logo.altTag')} />
			)}
			<div className="logo__stripes flex flex--direction-column">
				<hr className="hr" />
				<hr className="hr" />
				<hr className="hr" />
			</div>
		</div>
	)
}

export default Logo
