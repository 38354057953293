import { API } from 'aws-amplify'
import {
	ChangeUserContactArguments,
	ChangeUserPasswordArguments,
	GetCurrentUserMetadataQuery,
	RegisterUserArguments,
	SetChangeUserContactDetailsMutation,
	SetChangeUserPasswordMutation,
	SetRegisterUserMutation,
	SetUpdateUserSettingsMutation,
} from 'graphql'
import {
	setChangeUserContactDetails,
	setChangeUserPassword,
	setRegisterUser,
	setUpdateUserSettings,
} from 'graphql/mutations'
import { getCurrentUserMetadata } from 'graphql/queries'
import { trackPromise } from 'react-promise-tracker'

export enum UserRoles {
	Viewer = 'viewer',
	Registered = 'registered',
}

export enum AuthQueryKey {
	userMetaData = 'userMetaData',
}

const useAuthApi = () => {
	const getUserData = async () => {
		const query = API.graphql({
			query: getCurrentUserMetadata,
		})

		const responseData: { data: GetCurrentUserMetadataQuery } = await trackPromise(query as any)

		if (!responseData.data.getCurrentUserMetadata) {
			return null
		}

		const { data = null, profileData = null } = responseData.data.getCurrentUserMetadata

		return {
			userMetaData: data,
			profileData,
		}
	}

	const registerUser = async (input: RegisterUserArguments): Promise<SetRegisterUserMutation['setRegisterUser']> => {
		const query = API.graphql({
			query: setRegisterUser,
			variables: { input },
		})

		try {
			const responseData: { data: SetRegisterUserMutation } = await trackPromise(query as any)

			return responseData.data.setRegisterUser
		} catch (err: any) {
			return { status: 400, ...err }
		}
	}

	const setLanguage = async (lng: string) => {
		const query = API.graphql({
			query: setUpdateUserSettings,
			variables: { input: { languagePreferred: lng } },
		})

		try {
			const responseData: { data: SetUpdateUserSettingsMutation } = await trackPromise(query as any)
			return responseData
		} catch (err: any) {
			return { status: 400, ...err }
		}
	}

	const changeUserPassword = async (
		input: ChangeUserPasswordArguments
	): Promise<SetChangeUserPasswordMutation['setChangeUserPassword']> => {
		const query = API.graphql({
			query: setChangeUserPassword,
			variables: { input },
		})

		try {
			const responseData: { data: SetChangeUserPasswordMutation } = await trackPromise(query as any)

			return responseData.data.setChangeUserPassword
		} catch (err: any) {
			return { status: 400, ...err }
		}
	}

	const changeUserContactDetails = async (
		input: ChangeUserContactArguments
	): Promise<SetChangeUserContactDetailsMutation['setChangeUserContactDetails']> => {
		const query = API.graphql({
			query: setChangeUserContactDetails,
			variables: { input },
		})

		try {
			const responseData: { data: SetChangeUserContactDetailsMutation } = await trackPromise(query as any)

			return responseData.data.setChangeUserContactDetails
		} catch (err: any) {
			return { status: 400, ...err }
		}
	}

	return {
		getUserData,
		registerUser,
		setLanguage,
		changeUserPassword,
		changeUserContactDetails,
	}
}

export default useAuthApi
