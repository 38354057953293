import { IconType } from 'components/Icons'
import { UserRoles } from 'hooks/useAuthApi'

import { lazy } from 'react'
import { Trans } from 'react-i18next'
import { Flag } from 'shared/components/Flag/Flag'
import { RouteItem } from 'shared/hooks/useRouteHelper'

const ChangeLanguage = lazy(() => import('./views/ChangeLanguage/ChangeLanguage'))

const Dashboard = lazy(() => import('./views/Dashboard'))
const Timeline = lazy(() => import('./views/Timeline'))
const Logout = lazy(() => import('./views/Logout'))
const Login = lazy(() => import('./views/Login'))
const Register = lazy(() => import('./views/Register'))
const RegisterHelp = lazy(() => import('./views/RegisterHelp'))
const PasswordReset = lazy(() => import('./views/PasswordReset'))
const Imprint = lazy(() => import('./views/Imprint'))
const Trouble = lazy(() => import('./views/Trouble'))

const ProfileRouteWrapper = lazy(() => import('./views/profile/route-profile-wrapper'))
const ChangeAddressSummary = lazy(() => import('./views/ChangeAddress/ChangeAddressSummary'))
const ChangeAddressDashboard = lazy(() => import('./views/ChangeAddress/ChangeAddressDashboard'))
const ChangeAddressConsent = lazy(() => import('./views/ChangeAddress/ChangeAddressConsent'))
const ChangeAddressAllowanceForm = lazy(() => import('./views/ChangeAddress/ChangeAddressAllowanceForm'))
const ChangeAddressForm = lazy(() => import('./views/ChangeAddress/ChangeAddressForm'))
const ChangeAddressGoToAllowance = lazy(() => import('./views/ChangeAddress/ChangeAddressGoToAllowance'))
const ChangeAddressRouteWrapper = lazy(() => import('./views/ChangeAddress/ChangeAddressRouteWrapper'))
const MeterReadingDashboard = lazy(() => import('./views/ChangeAddress/MeterReading/MeterReadingDashboard'))
const MeterReadingForm = lazy(() => import('./views/ChangeAddress/MeterReading/MeterReadingForm'))
const ChangeBankAccountConsent = lazy(() => import('./views/ChangeBankAccount/ChangeBankAccountConsent'))
const ChangeBankAccountForm = lazy(() => import('./views/ChangeBankAccount/ChangeBankAccountForm'))
const ChangeBankAccountRouteWrapper = lazy(() => import('./views/ChangeBankAccount/ChangeBankAccountRouteWrapper'))
const ChangeBankAccountSummary = lazy(() => import('./views/ChangeBankAccount/ChangeBankAccountSummary'))

const ChangeAddress = lazy(() => import('./views/profile/profile-change-address'))
const ChangeBank = lazy(() => import('./views/profile/profile-change-bank'))
const ChangeEmail = lazy(() => import('./views/profile/profile-change-email'))
const ChangePassword = lazy(() => import('./views/profile/profile-change-password'))
const ChangePhoneNumber = lazy(() => import('./views/profile/profile-change-phone-number'))
const ProfileOverview = lazy(() => import('./views/profile/profile-overview'))

const routesDictionary: { [key: string]: RouteItem } = {
	dashboard: {
		path: '/dashboard',
		view: <Dashboard />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.dashboard.navigationName" />,
		hideIfLoggedOut: true,
		icon: IconType.dashboard,
		allowStatus: [UserRoles.Registered],
	},
	profile: {
		path: '/profile',
		view: <ProfileRouteWrapper />,
		showInTopNavigation: true,
		navigationName: <Trans i18nKey="view.profile.navigationName" />,
		hideIfLoggedOut: true,
		icon: IconType.settings,
		children: {
			overview: {
				path: '/overview',
				view: <ProfileOverview></ProfileOverview>,
				navigationName: <Trans i18nKey="view.profile.menu.overview" />,
				icon: IconType.dashboard,
			},
			password: {
				path: '/password',
				view: <ChangePassword></ChangePassword>,
				navigationName: <Trans i18nKey="view.profile.menu.password" />,
				icon: IconType.lock,
			},
			phone: {
				path: '/phone',
				view: <ChangePhoneNumber></ChangePhoneNumber>,
				navigationName: <Trans i18nKey="view.profile.menu.phone" />,
				icon: IconType.phone,
			},
			email: {
				path: '/email',
				view: <ChangeEmail></ChangeEmail>,
				navigationName: <Trans i18nKey="view.profile.menu.mail" />,
				icon: IconType.email,
			},
			address: {
				path: '/address',
				view: <ChangeAddress></ChangeAddress>,
				navigationName: <Trans i18nKey="view.profile.menu.address" />,
				icon: IconType.house,
			},
			bank: {
				path: '/bank',
				view: <ChangeBank></ChangeBank>,
				navigationName: <Trans i18nKey="view.profile.menu.bank" />,
				icon: IconType.card,
			},
		},
		allowStatus: [UserRoles.Registered],
	},
	changeAddress: {
		path: '/change-address',
		view: <ChangeAddressDashboard />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.changeAddress.navigationName" />,
		icon: IconType.house,
		children: {
			living: {
				path: '/living',
				view: <ChangeAddressRouteWrapper type="living" />,
				children: {
					consent: {
						path: '/consent',
						view: <ChangeAddressConsent type="living" />,
					},
					form: {
						path: '/form',
						view: <ChangeAddressForm type="living" />,
					},
					allowance: {
						path: '/allowance',
						view: <ChangeAddressGoToAllowance />,
					},
					summary: {
						path: '/summary',
						view: <ChangeAddressSummary type="living" />,
					},
				},
			},
			postal: {
				path: '/postal',
				view: <ChangeAddressRouteWrapper type="postal" />,
				children: {
					consent: {
						path: '/consent',
						view: <ChangeAddressConsent type="postal" />,
					},
					form: {
						path: '/form',
						view: <ChangeAddressForm type="postal" />,
					},
					summary: {
						path: '/summary',
						view: <ChangeAddressSummary type="postal" />,
					},
				},
			},
			allowanceForm: {
				path: '/allowance-form',
				view: <ChangeAddressAllowanceForm />,
			},
			meterReading: {
				path: '/meter-reading',
				view: <MeterReadingDashboard />,
				children: {
					form: {
						path: '',
						view: <MeterReadingForm type={undefined} />,
						parameters: ['type'],
					},
				},
			},
		},
		allowStatus: [UserRoles.Registered],
	},
	changeBankAccount: {
		path: '/change-bank-account',
		view: <ChangeBankAccountRouteWrapper />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.changeBankAccount.navigationName" />,
		hideIfLoggedOut: true,
		icon: IconType.card,
		children: {
			consent: {
				path: '/consent',
				view: <ChangeBankAccountConsent />,
			},
			form: {
				path: '/form',
				view: <ChangeBankAccountForm />,
			},
			summary: {
				path: '/summary',
				view: <ChangeBankAccountSummary />,
			},
		},
		allowStatus: [UserRoles.Registered],
	},
	// sendDocuments: {
	// 	path: '/send-documents',
	// 	view: <Dashboard />,
	// 	showInNavigation: true,
	// 	navigationName: <Trans i18nKey="view.sendDocuments.navigationName" />,
	// 	hideIfLoggedOut: true,
	// 	icon: IconType.documents,
	// },
	// miscellaneous: {
	// 	path: '/misc',
	// 	view: <Dashboard />,
	// 	showInNavigation: true,
	// 	navigationName: <Trans i18nKey="view.miscellaneous.navigationName" />,
	// 	hideIfLoggedOut: true,
	// 	icon: IconType.chat,
	// },
	timeline: {
		path: '/timeline',
		view: <Timeline />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.timeline.navigationName" />,
		authRequired: true,
		hideIfLoggedOut: true,
		icon: IconType.letter,
		allowStatus: [UserRoles.Registered],
	},
	login: {
		path: '/login',
		view: <Login />,
		navigationName: <Trans i18nKey="view.login.navigationName" />,
		showInNavigation: true,
		authRequired: false,
		hideIfLoggedIn: true,
		icon: IconType.profile,
	},
	register: {
		path: '/register',
		view: <Register />,
		navigationName: <Trans i18nKey="view.register.navigationName" />,
		showInNavigation: true,
		authRequired: true,
		hideIfLoggedIn: false,
		icon: IconType.register,
		allowStatus: [UserRoles.Viewer],
		blockStatus: [UserRoles.Registered],
	},
	registerHelp: {
		path: '/register-help',
		view: <RegisterHelp />,
		navigationName: <Trans i18nKey="view.registerHelp.navigationName" />,
		showInNavigation: true,
		authRequired: false,
		hideIfLoggedIn: true,
		icon: IconType.largeHelp,
	},
	logout: {
		path: '/logout',
		showInNavigation: true,
		view: <Logout />,
		navigationName: <Trans i18nKey="view.logout.navigationName" />,
		authRequired: false,
		hideIfLoggedOut: true,
		icon: IconType.logout,
	},
	passwordReset: {
		path: '/password-reset',
		showInNavigation: true,
		view: <PasswordReset />,
		navigationName: <Trans i18nKey="view.passwordReset.navigationName" />,
		authRequired: false,
		hideIfLoggedIn: true,
		hideIfLoggedOut: false,
		blockStatus: [UserRoles.Viewer, UserRoles.Registered],
		icon: IconType.lock,
	},
	changeLanguage: {
		path: '/change-language',
		navigationName: <Trans i18nKey="view.changeLanguage.navigationName" />,
		view: <ChangeLanguage />,
		icon: IconType.house,
		iconNode: <Flag />,
		showInNavigation: true,
		hideIfLoggedOut: false,
		authRequired: false,
	},
	imprint: {
		path: '/imprint',
		view: <Imprint />,
		showInNavigation: true,
		authRequired: false,
		navigationName: <Trans i18nKey="view.imprint.navigationName" />,
		hideIfLoggedIn: false,
	},
	trouble: {
		path: '/trouble',
		view: <Trouble />,
		showInNavigation: false,
		authRequired: false,
		hideIfLoggedIn: false,
	},
}

export default routesDictionary
