import classNames from 'classnames'
import { IconType } from 'components/Icons'
import { DefaultTFuncReturn } from 'i18next'
import { Link } from 'raviger'
import React, { FunctionComponent, HTMLAttributes, useMemo, useState } from 'react'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import Icon, { IconSize } from './Icon'
import LoadingSpinner from './LoadingSpinner'

export enum ButtonType {
	highlight = 'highlight',
	primary = 'primary',
	secondary = 'secondary',
	medium = 'medium',
	small = 'small',
	text = 'text',
	round = 'round',
	link = 'link',
	countdown = 'countdown',
}

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
	label?: string | React.ReactElement | DefaultTFuncReturn
	icon?: IconType
	iconRotate?: number
	iconColor?: string
	iconSize?: IconSize
	type?: ButtonType | ButtonType[]
	submit?: boolean
	onClick?: any
	className?: string
	disabled?: boolean
	route?: string
	hidden?: boolean
	href?: string | DefaultTFuncReturn
	internalLink?: boolean
	ref?: React.Ref<any>
	iconRef?: React.Ref<any>
	target?: string
	download?: boolean
	tabIndex?: number
	promiseTracker?: {
		area: string
		delay?: number
	}
}

const Button: FunctionComponent<ButtonProps> = (props) => {
	const { navigateTo } = useRouteHelper()
	const [promiseInProgress, setPromiseInProgress] = useState<boolean>(false)

	const getClassNames = (): string => {
		const classPrefix: string = 'button--'
		const classes: string[] = ['button']

		if (props.className) {
			classes.push(props.className)
		}

		if ('string' === typeof props.type) {
			classes.push(classPrefix + props.type)
		} else if ('object' === typeof props.type) {
			for (const type of props.type) {
				classes.push(classPrefix + type)
			}
		}

		return classes.join(' ')
	}

	const onClick = (e: any) => {
		if (promiseInProgress === true) {
			return
		}

		if (props.route) {
			navigateTo(props.route)
		}

		if (props.onClick) {
			props.onClick(e)
		}
	}

	const buttonContent = useMemo(() => {
		const label = (
			<>
				{props.icon && (
					<Icon
						type={props.icon}
						rotate={props.iconRotate}
						color={props.iconColor}
						ref={props.iconRef}
						size={props.iconSize}
					/>
				)}
				{props.label}
				{props.children}
			</>
		)

		if (props.promiseTracker !== undefined) {
			return (
				<>
					<LoadingSpinner
						delay={0}
						{...props.promiseTracker}
						onPromiseInProgressChange={setPromiseInProgress}
						className="submit__loading"
					/>
					<span
						className={classNames('visually-hidden', {
							'visually-hidden--false': promiseInProgress === false,
						})}
					>
						{label}
					</span>
				</>
			)
		}

		return label
	}, [
		props.icon,
		props.iconRotate,
		props.iconColor,
		props.iconRef,
		props.iconSize,
		props.label,
		props.children,
		props.promiseTracker,
		promiseInProgress,
	])

	if (true === props.hidden) {
		return null
	}

	const TagName = props.href ? (props.internalLink ? Link : 'a') : 'button'

	return (
		<TagName
			style={props.style}
			ref={props.ref}
			type={props.submit ? 'submit' : 'button'}
			className={getClassNames()}
			onClick={onClick}
			disabled={props.disabled}
			tabIndex={props.tabIndex}
			href={props.href as string}
			target={props.target}
			download={props.download}
		>
			<span>{buttonContent}</span>
		</TagName>
	)
}

Button.defaultProps = {
	type: [],
}

export default Button
