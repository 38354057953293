import { TargetLanguageCode } from 'deepl-node'
import { availableLanguages } from 'i18n-languages'
import i18next from 'i18next'
import { FunctionComponent, SVGProps } from 'react'
import { ReactComponent as GermanFlag } from './flag-de.svg'
import { ReactComponent as EnglishFlag } from './flag-en-US.svg'
import { ReactComponent as SpanishFlag } from './flag-es.svg'
import { ReactComponent as FrenchFlag } from './flag-fr.svg'
import { ReactComponent as PolishFlag } from './flag-pl.svg'
import { ReactComponent as TurkishFlag } from './flag-tr.svg'

export const flagAssignment: { [key: string]: FunctionComponent<SVGProps<SVGSVGElement>> } = {
	es: SpanishFlag,
	'en-US': EnglishFlag,
	de: GermanFlag,
	fr: FrenchFlag,
	tr: TurkishFlag,
	pl: PolishFlag,
}

export const Flag: FunctionComponent<{ lang?: TargetLanguageCode; className?: string }> = ({ lang, className }) => {
	const flagKey = lang ?? (i18next.language as TargetLanguageCode)

	if (!availableLanguages.includes(flagKey)) {
		return <></>
	}

	const FlagTag = flagAssignment[flagKey]

	return <FlagTag className={className} />
}
