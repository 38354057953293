import { useEffect, useState } from 'react'

export const useBreakpointClasses = (breakpoints: { [key: string]: number }) => {
	const isClient = typeof window === 'object'
	const [activeBreakpoints, setActiveBreakpoints] = useState<{ [key: string]: boolean }>()

	function getSize() {
		if (isClient) {
			const sizes = []

			for (const [breakpoint, minSize] of Object.entries(breakpoints)) {
				if (window.innerWidth >= minSize) {
					sizes.push(breakpoint)
				}
			}

			return sizes
		} else {
			return undefined
		}
	}

	useEffect((): any => {
		if (!isClient) {
			return false
		}

		function handleResize() {
			const size = getSize()
		
			if (undefined !== size) {
				const activeBreakpoints: { [key: string]: boolean } = {}
				for (const breakpoint of Object.keys(breakpoints)) {
					const isActive = size.includes(breakpoint)
					document.documentElement.classList.toggle(`breakpoint-${breakpoint}`, isActive)
					activeBreakpoints[breakpoint] = isActive
				}
				setActiveBreakpoints(activeBreakpoints)
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
		// eslint-disable-next-line
	}, [])

	return activeBreakpoints
}
