import React, { useEffect } from 'react'
import globalHook, { Store } from 'use-global-hook'

// Defining your own state and associated actions is required
export interface NavigationState {
	open: boolean
	backButtonPath: string | undefined
	backButtonClickHandler:
		| {
				name: (parameters?: any) => void
				parameters?: any
		  }
		| undefined
	viewTitle: string | React.ReactElement | undefined
}

// Associated actions are what's expected to be returned from globalHook
export interface NavigationActions {
	setOpen: (open: boolean) => void
	setBackButtonPath: (backButtonPath: NavigationState['backButtonPath']) => void
	setBackButtonClickHandler: (backButtonClickHandler: NavigationState['backButtonClickHandler']) => void
	setViewTitle: (viewTitle: NavigationState['viewTitle']) => void
	resetState: () => void
}

const setOpen = (store: Store<NavigationState, NavigationActions>, open: boolean) => {
	if (open === store.state.open) {
		return
	}

	store.setState({ ...store.state, open })
}

const setBackButtonPath = (
	store: Store<NavigationState, NavigationActions>,
	backButtonPath: NavigationState['backButtonPath']
) => {
	store.setState({ ...store.state, backButtonClickHandler: undefined, backButtonPath })
}

const setBackButtonClickHandler = (
	store: Store<NavigationState, NavigationActions>,
	backButtonClickHandler: NavigationState['backButtonClickHandler']
) => {
	store.setState({ ...store.state, backButtonPath: undefined, backButtonClickHandler })
}

const setViewTitle = (store: Store<NavigationState, NavigationActions>, viewTitle: NavigationState['viewTitle']) => {
	store.setState({ ...store.state, viewTitle })
}

const resetState = (store: Store<NavigationState, NavigationActions>) => {
	store.setState({ ...initialState, open: store.state.open })
}

const initialState: NavigationState = {
	open: false,
	backButtonPath: undefined,
	backButtonClickHandler: undefined,
	viewTitle: undefined,
}

// actions passed to globalHook do not need to be typed
const actions = {
	setOpen,
	setBackButtonPath,
	setBackButtonClickHandler,
	setViewTitle,
	resetState,
}

const useNavigationState = globalHook<NavigationState, NavigationActions>(React, initialState, actions)

export default useNavigationState
export const useBackButtonPath = (path: NavigationState['backButtonPath'] = undefined) => {
	const [value, setValue] = useNavigationState<
		NavigationState['backButtonPath'],
		NavigationActions['setBackButtonPath']
	>(
		(navigationState: NavigationState) => navigationState.backButtonPath,
		(navigationActions: NavigationActions) => navigationActions.setBackButtonPath
	)

	useEffect(() => {
		if (value !== path) {
			setValue(path)
		}
		// eslint-disable-next-line
	}, [])
}

export const useBackButtonClickHandler = (handler: NavigationState['backButtonClickHandler'] = undefined) => {
	const [, setValue] = useNavigationState<NavigationActions['setBackButtonClickHandler']>(
		undefined,
		(navigationActions: NavigationActions) => navigationActions.setBackButtonClickHandler
	)

	useEffect(() => {
		setValue(handler)
		// eslint-disable-next-line
	}, [handler?.parameters])
}

export const useViewTitle = (title: NavigationState['viewTitle']) => {
	const [value, setValue] = useNavigationState<NavigationState['viewTitle'], NavigationActions['setViewTitle']>(
		(navigationState: NavigationState) => navigationState.viewTitle,
		(navigationActions: NavigationActions) => navigationActions.setViewTitle
	)

	useEffect(() => {
		if (value !== title) {
			setValue(title)
		}
		// eslint-disable-next-line
	}, [])
}
