/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCurrentUserMetadata = /* GraphQL */ `
  query GetCurrentUserMetadata {
    getCurrentUserMetadata {
      data {
        firstName
        lastName
        postalAddressAvailable
        settings {
          languagePreferred
          mfaPreferred
        }
      }
      profileData {
        email
        phoneNr
        postalAddress {
          type
          recipient
          careOf
          city
          countryCode
          houseNumber
          street
          validFrom
          validTo
          zip
        }
        livingAddress {
          type
          recipient
          careOf
          city
          countryCode
          houseNumber
          street
          validFrom
          validTo
          zip
        }
        bankDetails {
          accountHolder
          isDefaultAccountHolder
          iban
          bic
          accountNumber
          bankNationalId
          countryCode
          bankName
          validFrom
        }
      }
      PK
      SK
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      tree
      treePath
    }
  }
`;
export const getValidateSwiftIban = /* GraphQL */ `
  query GetValidateSwiftIban($input: ValidateSwiftIban!) {
    getValidateSwiftIban(input: $input) {
      valid
      bankDetails {
        name
        nationalId
        branchId
        countryCode
        bic
      }
      message
    }
  }
`;
export const getValidateSwiftNationalId = /* GraphQL */ `
  query GetValidateSwiftNationalId($input: ValidateSwiftNationalId!) {
    getValidateSwiftNationalId(input: $input) {
      valid
      bankDetails {
        name
        nationalId
        branchId
        countryCode
        bic
      }
      message
    }
  }
`;
export const getValidateAddressZip = /* GraphQL */ `
  query GetValidateAddressZip($input: ValidateAddressZip!) {
    getValidateAddressZip(input: $input) {
      valid
      addressDetails {
        zipCode
        countryCode
        city
      }
      message
    }
  }
`;
export const getValidCountryCodes = /* GraphQL */ `
  query GetValidCountryCodes($input: CountryCodeFilter) {
    getValidCountryCodes(input: $input) {
      countryCode
      labelDE
      iban
    }
  }
`;
export const getUserTicketStatus = /* GraphQL */ `
  query GetUserTicketStatus($input: UserTicketStatusFilter) {
    getUserTicketStatus(input: $input) {
      openTickets {
        ticketId
        ticketType
        subType
        cancelable
        createdAt
        validFrom
        validTo
        ticketTypeDetails {
          bankdetails {
            accountHolder
            accountNumber
            iban
            bic
            bank
            bankCode
            countryCode
            validFrom
          }
          address {
            recipient
            careOf
            city
            countryCode
            houseNumber
            street
            validFrom
            zip
            gasMeter
            energyMeter
          }
        }
      }
      closedTickets {
        ticketId
        ticketType
        subType
        cancelable
        createdAt
        validFrom
        validTo
        ticketTypeDetails {
          bankdetails {
            accountHolder
            accountNumber
            iban
            bic
            bank
            bankCode
            countryCode
            validFrom
          }
          address {
            recipient
            careOf
            city
            countryCode
            houseNumber
            street
            validFrom
            zip
            gasMeter
            energyMeter
          }
        }
      }
    }
  }
`;
export const getUserAllowanceStatus = /* GraphQL */ `
  query GetUserAllowanceStatus($input: UserAllowanceStatusFilter) {
    getUserAllowanceStatus(input: $input) {
      energy {
        subtype
        ticketId
        state
      }
      gas {
        subtype
        ticketId
        state
      }
    }
  }
`;
export const getUserAllowanceAddressStatus = /* GraphQL */ `
  query GetUserAllowanceAddressStatus($input: UserAllowanceAddressStatus!) {
    getUserAllowanceAddressStatus(input: $input) {
      energy
      gas
    }
  }
`;
export const getTimeline = /* GraphQL */ `
  query GetTimeline {
    getTimeline {
      entries {
        __typename
        date
        ... on TimelineDocumentEntry {
          content {
            type
            ticketId
          }
        }
      }
    }
  }
`;
export const getDocumentLink = /* GraphQL */ `
  query GetDocumentLink($input: DocumentLinkArguments!) {
    getDocumentLink(input: $input) {
      url
    }
  }
`;