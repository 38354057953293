/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const setCreateTicketAddress = /* GraphQL */ `
  mutation SetCreateTicketAddress($input: CreateTicketAddress!) {
    setCreateTicketAddress(input: $input) {
      data {
        careOf
        city
        countryCode
        houseNumber
        street
        validFrom
        validTo
        zip
        gasMeter
        energyMeter
      }
      PK
      SK
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      tree
      treePath
    }
  }
`;
export const setCreateTicketPostalAddress = /* GraphQL */ `
  mutation SetCreateTicketPostalAddress($input: CreateTicketPostalAddress!) {
    setCreateTicketPostalAddress(input: $input) {
      data {
        recipient
        careOf
        city
        countryCode
        houseNumber
        street
        validFrom
        validTo
        zip
      }
      PK
      SK
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      tree
      treePath
    }
  }
`;
export const setCreateTicketBankDetails = /* GraphQL */ `
  mutation SetCreateTicketBankDetails($input: CreateTicketBankDetails!) {
    setCreateTicketBankDetails(input: $input) {
      data {
        accountHolder
        iban
        bic
        bank
        validFrom
      }
      PK
      SK
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      tree
      treePath
    }
  }
`;
export const setCreateTicketBankDetailsNonIban = /* GraphQL */ `
  mutation SetCreateTicketBankDetailsNonIban(
    $input: CreateTicketBankDetailsNonIban!
  ) {
    setCreateTicketBankDetailsNonIban(input: $input) {
      data {
        accountHolder
        accountNumber
        bank
        bankCode
        countryCode
        validFrom
      }
      PK
      SK
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      tree
      treePath
    }
  }
`;
export const setCreateTicketEpvGas = /* GraphQL */ `
  mutation SetCreateTicketEpvGas(
    $ticketId: String!
    $input: CreateTicketEpvGas!
  ) {
    setCreateTicketEpvGas(ticketId: $ticketId, input: $input) {
      PK
      SK
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      tree
      treePath
    }
  }
`;
export const setCreateTicketEpvGasReading = /* GraphQL */ `
  mutation SetCreateTicketEpvGasReading(
    $ticketId: String!
    $input: CreateTicketEpvGasReading!
  ) {
    setCreateTicketEpvGasReading(ticketId: $ticketId, input: $input) {
      PK
      SK
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      tree
      treePath
    }
  }
`;
export const setCreateTicketEpvEnergy = /* GraphQL */ `
  mutation SetCreateTicketEpvEnergy(
    $ticketId: String!
    $input: CreateTicketEpvEnergy!
  ) {
    setCreateTicketEpvEnergy(ticketId: $ticketId, input: $input) {
      PK
      SK
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      tree
      treePath
    }
  }
`;
export const setCreateTicketEpvEnergyReading = /* GraphQL */ `
  mutation SetCreateTicketEpvEnergyReading(
    $ticketId: String!
    $input: CreateTicketEpvEnergyReading!
  ) {
    setCreateTicketEpvEnergyReading(ticketId: $ticketId, input: $input) {
      PK
      SK
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      tree
      treePath
    }
  }
`;
export const setCancelPostalAddress = /* GraphQL */ `
  mutation SetCancelPostalAddress($input: CancelPostalAddressArguments!) {
    setCancelPostalAddress(input: $input) {
      state
    }
  }
`;
export const setCancelUserTicket = /* GraphQL */ `
  mutation SetCancelUserTicket($ticketId: String!) {
    setCancelUserTicket(ticketId: $ticketId) {
      state
    }
  }
`;
export const setUpdateUserSettings = /* GraphQL */ `
  mutation SetUpdateUserSettings($input: UserSettingsArguments!) {
    setUpdateUserSettings(input: $input) {
      languagePreferred
      mfaPreferred
    }
  }
`;
export const setChangeUserContactDetails = /* GraphQL */ `
  mutation SetChangeUserContactDetails($input: ChangeUserContactArguments!) {
    setChangeUserContactDetails(input: $input) {
      email
      phoneNr
      state
      errors {
        email
        emailTan
        phoneNr
        phoneNrTan
        state
      }
    }
  }
`;
export const setRegisterUser = /* GraphQL */ `
  mutation SetRegisterUser($input: RegisterUserArguments!) {
    setRegisterUser(input: $input) {
      registrationCode
      email
      phoneNr
      password
      state
      errors {
        registrationCode
        email
        emailTan
        phoneNr
        phoneNrTan
        password
        state
      }
    }
  }
`;
export const setChangeUserPassword = /* GraphQL */ `
  mutation SetChangeUserPassword($input: ChangeUserPasswordArguments!) {
    setChangeUserPassword(input: $input) {
      oldPassword
      newPassword
      state
      errors {
        oldPassword
        newPassword
      }
    }
  }
`;
