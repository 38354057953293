import { Link } from 'raviger'
import { FunctionComponent } from 'react'
import Icon from 'shared/components/Icon'
import { RouteItem } from 'shared/hooks/useRouteHelper'

export const NavigationItem: FunctionComponent<{ route: RouteItem; routeParent?: string; path: string | null }> = ({
	route,
	routeParent = '',
	path,
}) => {
	const navigationItemPath = route.path
	const navigationItemClass = route.path.split('/')[1]

	const activeIcon =
		null !== path &&
		(path === routeParent + navigationItemPath ||
			(navigationItemPath.length !== 0 && path.startsWith(navigationItemPath)))

	return (
		<div
			className={`sidebar-navigation__item sidebar-navigation__item--${navigationItemClass} ${
				activeIcon ? 'sidebar-navigation__item--active' : ''
			}`}
		>
			<Link className="sidebar-navigation__link" href={routeParent + navigationItemPath}>
				{route.iconNode ? (
					<div className="sidebar-navigation__node-icon">{route.iconNode}</div>
				) : (
					route.icon && <Icon type={route.icon} className="sidebar-navigation__icon" />
				)}
				<span className="sidebar-navigation__link-text">{route.navigationName || route.path}</span>
			</Link>
		</div>
	)
}
