import { UserRoles } from 'hooks/useAuthApi'
import Sidebar from 'partials/Sidebar'
import { Redirect, usePath, useRoutes } from 'raviger'
import React, { Suspense, useEffect } from 'react'
import routesDictionary from 'routes'
import AppUpdateModal from 'shared/components/AppUpdateModal'
import GlobalModal from 'shared/components/GlobalModal'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useAuth } from '../hooks/useAuth'
import NotFound from './NotFound'

const NON_INITIAL_PATHS = [
	routesDictionary.logout.path,
	routesDictionary.login.path,
	routesDictionary.register.path,
	routesDictionary.passwordReset.path,
]

const pathStartsWithInitialPath = (currentPath: string) => {
	return NON_INITIAL_PATHS.some((path) => path.startsWith(currentPath))
}

const RouteWrapper: React.FC = () => {
	const { navigateTo, routes, getMainPath, filteredRoutesDictionary } = useRouteHelper()
	const { userData } = useAuth()
	const routeResult = useRoutes(routes)
	const path = usePath()

	/**
	 * save the initially visited path, to make sure to redirect the user to the
	 * desired page after authentication.
	 * Authentication only runs after the first render,
	 * where the private routes are not available, yet.
	 *
	 * When the user is authenticated an effect runs and navigates to the initial path.
	 */

	if (undefined === userData && null === sessionStorage.getItem('initialPath')) {
		if (null !== path && path !== '/' && pathStartsWithInitialPath(path) === false) {
			sessionStorage.setItem('initialPath', path)
		} else {
			sessionStorage.setItem('initialPath', 'dynamic')
		}
	}

	useEffect(() => {
		if (undefined !== userData) {
			let initialPath: string | null | undefined = sessionStorage.getItem('initialPath')

			if (null === initialPath) {
				return
			}

			const userIsRegistered = userData.roles.includes(UserRoles.Registered)

			// if the saved path is not found in filteredRoutesDictionary, set initialPath to 'dynamic'
			// if (false === filteredRoutesDictionary.some((route) => route.path === initialPath)) {
			// 	initialPath = 'dynamic'
			// }

			// if initialPath is not found in routes, prevent 404 and switch to dynamic path routing
			if (
				initialPath !== 'dynamic' &&
				Object.keys(routes).find((route) => initialPath && initialPath.startsWith(route.replace('*', ''))) ===
					undefined
			) {
				initialPath = 'dynamic'
			}

			// if initialPath is 'dynamic', visit the first valid route
			if (initialPath === 'dynamic') {
				initialPath = getMainPath(userIsRegistered ? filteredRoutesDictionary[0] : routesDictionary.register)
			}

			if (initialPath) {
				sessionStorage.removeItem('initialPath')

				navigateTo(initialPath, true)
			}
		}
	}, [filteredRoutesDictionary, getMainPath, navigateTo, routes, userData])

	return (
		<>
			<Sidebar />
			<main className="main">
				{routeResult ? (
					<Suspense>{routeResult}</Suspense>
				) : userData ? (
					<NotFound />
				) : (
					<Redirect to={routesDictionary.login.path} replace={true} />
				)}
			</main>
			<GlobalModal />
			<AppUpdateModal />
			<LoadingSpinner />
		</>
	)
}

export default RouteWrapper
